import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import '../stylesheets/Users.css';
import {getUserPreviews} from "../services/UserService";


const Users  = () => {

    const [users, setUsers] = useState([]);

    useEffect(()=>{
        const token = localStorage.getItem("admin-token");
        getUserPreviews(token)
            .then(response => {
                if (response.status == 200) {
                    setUsers(response.data);
                }
            })
            .catch(err => {

            })
    },[]);

    return(
        <div>
            {users.map((user) => (
                <div className={"user-preview-div"}>
                    <img className={"user-profile"} src={"data:" + user.profile_image_type + ";base64," + user.profile_image} />
                    <div className={"d-inline-block"}>
                        <h3>{user.first_name} {user.last_name}</h3>
                        <p>{user.posts_count} Posts</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Users
