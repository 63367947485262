import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {
    getAestheticPreviews,
    updateAesthetic,
    createAesthetic,
    getAestheticSubmissions, deleteAestheticSubmissions
} from "../services/AestheticService";
import "../stylesheets/AestheticSubmissions.css";
import Modal from "react-modal";


const AestheticSubmissions  = () => {

    const [aesthetics, setAesthetics] = useState([]);
    const [editAesthetic, setEditAesthetic] = useState(false);
    const [deleteAesthetic, setDeleteAesthetic] = useState(false);
    const [selectedAesthetic, setSelectedAesthetic] = useState({});
    const [newAesthetic, setNewAesthetic] = useState("");

    function closeModals() {
        setEditAesthetic(false);
        setDeleteAesthetic(false);
        setSelectedAesthetic({});
        setNewAesthetic("");
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openEditAesthetic(aesthetic) {
        setNewAesthetic(aesthetic.aesthetic);
        setSelectedAesthetic(aesthetic);
        setEditAesthetic(true);
    }

    function openDeleteAesthetic(aesthetic) {
        setSelectedAesthetic(aesthetic);
        setDeleteAesthetic(true);
    }

    function refreshAesthetics() {
        const token = localStorage.getItem("admin-token");
        getAestheticSubmissions(token)
            .then((res) => {
                setAesthetics(res.data);
            })
            .catch(err => {

            })
    }

    const deleteAestheticSubmission = (event) => {
        event.preventDefault();
        const token = localStorage.getItem("admin-token");
        deleteAestheticSubmissions(selectedAesthetic.id, token)
            .then((res) => {
                closeModals();
                refreshAesthetics();
            })
            .catch(err => {

            })
    }

    const updateAestheticEntry = (event) => {
        event.preventDefault();
        let newAestheticObj = {}
        newAestheticObj.aesthetic = newAesthetic
        const token = localStorage.getItem("admin-token");
        createAesthetic(newAestheticObj, token)
            .then((res) => {
                if (res.status == 200) {
                    deleteAestheticSubmission(event);
                } else {
                    closeModals();
                    refreshAesthetics();
                }
            })
            .catch(err => {

            })
    }

    useEffect(()=>{
        refreshAesthetics();
    },[]);

    return(
        <div>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Aesthetic</th>
                    <th scope="col">Description</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                {aesthetics && aesthetics.map((aesthetic) => (
                    <tr>
                        <td>{aesthetic.aesthetic}</td>
                        <td>{aesthetic.description}</td>
                        <td>
                            <button className={"btn btn-outline-success"} onClick={e => {openEditAesthetic(aesthetic)}}>Approve</button>
                            <button className={"btn btn-outline-danger deny-aeth-sub-btn"} onClick={e => {openDeleteAesthetic(aesthetic)}}>Deny</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <Modal
                isOpen={editAesthetic}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModals}
                className={"aesthetic-modal"}
            >
                <div className={"aesthetic-edit-container"}>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputEmail4">Current Name</label>
                                <input className="form-control" value={selectedAesthetic.aesthetic} disabled/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputPassword4">New Aesthetic</label>
                                <input className="form-control" value={newAesthetic} onChange={e => setNewAesthetic(e.target.value)}/>
                            </div>
                        </div>
                        <button className="btn btn-primary save-aesthetic-btn" onClick={updateAestheticEntry}>Save</button>
                    </form>
                </div>
            </Modal>
            <Modal
                isOpen={deleteAesthetic}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModals}
                className={"aesthetic-modal"}
            >
                <div className={"aesthetic-edit-container"}>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputEmail4">Current Name</label>
                                <input className="form-control" value={selectedAesthetic.aesthetic} disabled/>
                            </div>
                        </div>
                        <div className="form-row">
                            <h5 className={"h-warning"}>Are you sure you want to delete this aesthetic submission?</h5>
                        </div>
                        <button className="btn btn-outline-danger save-aesthetic-btn deny-aeth-sub-btn" onClick={deleteAestheticSubmission}>Delete</button>
                        <button className="btn btn-outline-secondary save-aesthetic-btn" onClick={closeModals}>Cancel</button>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default AestheticSubmissions
