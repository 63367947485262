import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import axios from "axios";
import {
    getAestheticContent,
    getContentSearch,
    getFlaggedContent,
    getReportedContent,
    getUnratedContent, updateReportedContent
} from "../services/ContentService";
import "../stylesheets/Reported.css";
import Modal from "react-modal";



const Reported  = () => {

    const [images, setImages] = useState([]);
    const [confModalOpen, setConfModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    function closeModals() {
        setConfModalOpen(false);
        setSelectedImage(null);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openConfirmationModal(image) {
        setSelectedImage(image);
        setConfModalOpen(true);
    }

    function denyContentReport() {
        let req = {};
        req.content_id = selectedImage.id;
        req.status = "DENIED";
        const token = localStorage.getItem("admin-token");
        updateReportedContent(token, req)
            .then(() => {
                setConfModalOpen(false);
            })
    }

    function resolveContentReport(image) {
        let req = {};
        req.content_id = image.id;
        req.status = "RESOLVED";
        const token = localStorage.getItem("admin-token");
        updateReportedContent(token, req);
    }

    useEffect(()=>{
        const token = localStorage.getItem("admin-token");
        getReportedContent(token)
            .then((res) => {
                setImages(res.data);
            })
            .catch(err => {

            })
    },[]);

    return(
        <div>
            {images && images.map(image => (
                <div className={"reported-content-container"}>
                    <img src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} className={"reported-img"} />
                    <div className={"content-info-div"}>
                        <h5>Uploaded by:</h5>
                        <div className={"inline-div"}>
                            <img src={"https://d3p3fw3rutb1if.cloudfront.net/users/" + image.user_id} className={"user-img-preview"}/>
                            <h5 className={"username-text"}>{image.username}</h5>
                        </div>
                        <h5>Statistics:</h5>
                        <div>
                            <p>{image.likes} likes</p>
                            <p>{image.views} views</p>
                        </div>
                    </div>
                    <div className={"content-info-div"}>
                        <div>
                            <button className={"btn btn-light report-button"} onClick={() => resolveContentReport(image)}>Ignore</button>
                        </div>
                        <div>
                            <button className={"btn btn-danger report-button"} onClick={() => openConfirmationModal(image)}>Deny Content</button>
                        </div>
                    </div>
                </div>
            ))}
            <Modal
                isOpen={confModalOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModals}
                className={"aesthetic-modal"}
            >
                <div>
                    <h5>Are you sure you want to deny this photo?</h5>
                    <div className={"deny-buttons-div"}>
                        <button className={"btn btn-light"} onClick={closeModals}>Cancel</button>
                        <button className={"btn btn-danger"} onClick={denyContentReport}>Deny</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Reported
