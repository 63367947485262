import axios from "axios";

export async function getAesthetics(token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function deleteAesthetic(id, token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics/" + id;
    const result = await axios.delete(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getAestheticPreviews(token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics/preview";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getAestheticSubmissions(token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics/submissions";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function deleteAestheticSubmissions(id, token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics/submissions/" + id;
    const result = await axios.delete(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function updateAesthetic(aesthetic, token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics";
    const result = await axios.put(url, aesthetic, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function createAesthetic(aesthetic, token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics";
    const result = await axios.post(url, aesthetic, { headers: {'X-CLIENT-ID': token}});
    return result;
}
