import React, {useState} from 'react'
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import {getProfile} from "../services/UserService";
import '../stylesheets/Login.css';
import openEye from "../icons/eye-open.png";
import closedEye from "../icons/eye-closed.png";


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [createNewPassword, setCreateNewPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [storedUser, setStoredUser] = useState({});
    const navigate = useNavigate();

    const createUser = (event) => {
        event.preventDefault();
        if (email && password) {
            Auth.signIn({
                password: password,
                username: email
            })
                .then((result) => {
                    if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setStoredUser(result);
                        setCreateNewPassword(true);
                    } else {
                        localStorage.setItem('admin-token', result.getSignInUserSession().getIdToken().getJwtToken())
                        navigate(0);
                    }
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }

    const updatePassword = (event) => {
        event.preventDefault();
        if (email && newPassword && newPassword === confirmPassword) {
            Auth.completeNewPassword(
                storedUser,
                newPassword
            )
                .then((result) => {
                    localStorage.setItem('admin-token', result.getSignInUserSession().getIdToken().getJwtToken())
                    navigate(0);
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return(
        <div>
            <div className="w-50 d-inline-block">
                <img className="login-img" src="https://dupe-photos.s3.amazonaws.com/welcome-back.png"/>
            </div>
            {!createNewPassword &&
            <div className="w-50 d-inline-block">
                <div className="w-50 mx-auto login-div">
                    <h3>Good to see you again!</h3>
                    <p>Dont have an account? Sign-up</p>
                    <a href="https://dupe-staging-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&response_type=code&client_id=3amaur3hqgljj17sfd00bprn05&redirect_uri=http://localhost:3000/oauth/">
                        <img className={"google-logo"}
                             src={"https://dupe-photos.s3.amazonaws.com/btn_google_signin_light_normal_web%402x.png"}/>
                    </a>
                    <p>OR</p>
                    <form className="row g-3">
                        <div className="col-12">
                            <input type="text" className="form-control" id="email" value={email}
                                   onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                        </div>
                        <div className="col-md-12 password-div">
                            <input type={showPassword ? "text" : "password"} className="form-control" id="password"
                                   value={password} onChange={(e) => setPassword(e.target.value)}
                                   placeholder="Password"/>
                            {showPassword &&
                            <img className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye}/>
                            }
                            {!showPassword &&
                            <img className={"password-eye-img"} onClick={toggleShowPassword} src={openEye}/>
                            }
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary login-page-btn" type="submit"
                                    onClick={createUser}>Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            }
            {createNewPassword &&
            <div className="w-50 d-inline-block">
                <div className="w-50 mx-auto login-div">
                    <h3>Please enter a new password</h3>
                    <form className="row g-3">
                        <div className="col-12">
                            <input type="text" className="form-control" id="email" value={email}
                                   onChange={(e) => setEmail(e.target.value)} placeholder="Email" disabled={true}/>
                        </div>
                        <div className="col-md-12 password-div">
                            <input type={showPassword ? "text" : "password"} className="form-control" id="password"
                                   value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                                   placeholder="Password"/>
                            {showPassword &&
                            <img className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye}/>
                            }
                            {!showPassword &&
                            <img className={"password-eye-img"} onClick={toggleShowPassword} src={openEye}/>
                            }
                        </div>
                        <div className="col-md-12 password-div">
                            <input type={showPassword ? "text" : "password"} className="form-control" id="password"
                                   value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                   placeholder="Password"/>
                            {showPassword &&
                            <img className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye}/>
                            }
                            {!showPassword &&
                            <img className={"password-eye-img"} onClick={toggleShowPassword} src={openEye}/>
                            }
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary login-page-btn" type="submit"
                                    onClick={updatePassword}>Set Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            }
        </div>

    );
}

export default Login
