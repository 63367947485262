import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import axios from "axios";
import {getAestheticContent, getContentSearch, getFlaggedContent, getUnratedContent} from "../services/ContentService";
import Content from "./Content";
import {useSearchParams} from "react-router-dom";
import NavBar from "./NavBar";


const Flagged  = () => {

    const [images, setImages] = useState([]);

    useEffect(()=>{
        const token = localStorage.getItem("admin-token");
        getFlaggedContent(token)
            .then((res) => {
                setImages(res.data);
            })
            .catch(err => {

            })
    },[]);

    return(
        <div>
            <Content content={images} addCollectionEnabled={true}/>
        </div>
    );
}

export default Flagged
