import axios from "axios";


export async function getProfile(token) {
    const result = await axios.get('https://staging-6gxsdymdsq-ue.a.run.app/api/v1/profile', { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getAllUsers(token) {
    const result = await axios.get('https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/users', { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getUserPreviews(token) {
    const result = await axios.get('https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/users/preview', { headers: {'X-CLIENT-ID': token}})
    return result;
}
