import axios from "axios";


export async function saveHomepage(req, token) {
    const result = await axios.post('https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/homepage/images', req, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getHomepageImages() {
    const result = await axios.get('https://dupe-backend-prod-6gxsdymdsq-ue.a.run.app/api/v1/homepage/images')
    return result;
}
