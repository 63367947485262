import logo from './logo.svg';
import './App.css';
import { Routes, Route} from "react-router-dom";
import Discovery from "./components/Discovery";
import Login from "./components/Login";
import {Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import React, {useEffect, useState} from "react";
import {getUnratedContent} from "./services/ContentService";
import Users from "./components/Users";
import NavBar from "./components/NavBar";
import Flagged from "./components/Flagged";
import Aesthetics from "./components/Aesthetics";
import AestheticSubmissions from "./components/AestheticSubmissions";
import HomePageEditor from "./components/HomePageEditor";
import TagEditor from "./components/TagEditor";
import Reported from "./components/Reported";

function App() {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(()=>{
        Amplify.configure(awsconfig);
        Auth.configure(awsconfig);

        const token = localStorage.getItem("admin-token");
        if (token) {
            setLoggedIn(true);
        } else {

        }
    },[]);

    if (loggedIn) {
        return (
            <div>
                <NavBar />
                <Routes>
                    <Route path="/homepage" element={<HomePageEditor/>} />
                    <Route path="/flagged" element={<Flagged/>} />
                    <Route path="/reported" element={<Reported/>} />
                    <Route path="/users" element={<Users/>} />
                    <Route path="/aesthetics" element={<Aesthetics/>} />
                    <Route path="/aesthetics/submissions" element={<AestheticSubmissions/>} />
                    <Route path="/tag_editor" element={<TagEditor/>} />
                    <Route path="*" element={<Discovery/>} />
                </Routes>
            </div>
        );

    } else {
        return (
            <div>
                <Login/>
            </div>
        )
    }
}

export default App;
