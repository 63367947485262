import axios from "axios";

export async function uploadContent(req, token) {
    const result = await axios.post('https://staging-6gxsdymdsq-ue.a.run.app/api/v1/content', req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function deleteContent(contentId, token) {
    const result = await axios.delete('https://staging-6gxsdymdsq-ue.a.run.app/api/v1/content/' + contentId, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function likeContent(contentId, token) {
    const result = await axios.post('https://staging-6gxsdymdsq-ue.a.run.app/api/v1/content/' + contentId + "/like", {}, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getContentSearch(searchValue, page) {
    let url = "https://content-api-staging-6gxsdymdsq-ue.a.run.app/api/v1/content"
    if (searchValue != null) {
        url = url + "/" + searchValue
    }
    if (page) {
        url = url + "?page=" + page;
    }
    const result = await axios.get(url);
    return result;
}

export async function getAestheticContent(aestheticId) {
    const url = "https://staging-6gxsdymdsq-ue.a.run.app/api/v1/aesthetics/" + aestheticId + "/content";
    const result = await axios.get(url);
    return result;
}

export async function getUsersContent(userId) {
    const url = "https://staging-6gxsdymdsq-ue.a.run.app/api/v1/users/" + userId + "/content";
    const result = await axios.get(url);
    return result;
}

export async function getContentSummary(contentId) {
    const url = "https://staging-6gxsdymdsq-ue.a.run.app/api/v1/content/" + contentId + "/preview";
    const result = await axios.get(url);
    return result;
}

export async function getUnratedContent(token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/unrated"
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getFlaggedContent(token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/flagged"
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function rateContent(req, contentId, token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/" + contentId + "/rating"
    const result = await axios.put(url, req,  { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getContentWithLabels(token, page) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/labels?page=" + page
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function createNewTags(token, req) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/labels"
    const result = await axios.post(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function deleteLabel(token, labelId) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/labels/" + labelId
    const result = await axios.delete(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getReportedContent(token) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/reported"
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function updateReportedContent(token, req) {
    const url = "https://dupe-admin-6gxsdymdsq-ue.a.run.app/api/v1/content/reported"
    const result = await axios.post(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}