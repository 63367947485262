import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react'
import axios from "axios";
import {getAestheticContent, getContentSearch, getFlaggedContent, getUnratedContent} from "../services/ContentService";
import Content from "./Content";
import {useSearchParams} from "react-router-dom";
import NavBar from "./NavBar";
import Modal from "react-modal";
import "../stylesheets/HomePageEditor.css";
import star from "../icons/star.png";
import {getHomepageImages, saveHomepage} from "../services/HomepageService";


const HomePageEditor  = () => {

    const [images, _setImages] = useState([]);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [homepageImages, setHomepageImages] = useState({});
    const [browseImages, setBrowseImages] = useState(false);
    const [index, setIndex] = useState(-1);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(2);

    function closeModal() {
        setBrowseImages(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openModal(_index) {
        setBrowseImages(true);
        setIndex(_index);
    }

    function selectPhoto(selectedImage) {
        selectedImage.order = index;
        homepageImages[index] = selectedImage;
        setHomepageImages(homepageImages);
        closeModal();
    }

    const setImages = (data) => {
        let newImages = [[],[],[],[],[]];
        if (data) {
            data.forEach((image, index) => {
                newImages[index % newImages.length].push(image)
            });
            _setImages(newImages);
            setIsLoading(false);
        }

    };

    function appendImages(data) {
        let newImages = [[],[],[],[],[]];
        if (data) {
            data.forEach((image, index) => {
                newImages[index % newImages.length].push(image)
            });
            setAdditionalImages(newImages);
            setIsLoading(false);
        }
    };

    function saveLayout() {
        let update = [];
        for (const [key, value] of Object.entries(homepageImages)) {
            let img = {};
            img.img_id = value.img_id;
            img.order = parseInt(key);
            update.push(img)
        }
        const token = localStorage.getItem("admin-token");
        saveHomepage(update, token)
            .then(resp => {
                window.location.reload();
            });
    }

    function parseHomepageImages(data) {
        let images = {};
        data.forEach((image, index) => {
            images[index] = image;
        });
        setHomepageImages(images);
    }

    function handleScroll() {
        const contentDiv = document.getElementById('content-container-div');
        if (contentDiv.getBoundingClientRect().bottom <= (window.innerHeight + 100) && !isLoading) {
            setIsLoading(true);
            getContentSearch(null, page)
                .then(resp => {
                    if (resp.status == 200) {
                        appendImages(resp.data);
                        setPage(page + 1);
                    }
                });
        } else {
            return;
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll, true);
    }, [isLoading]);

    useEffect(()=>{
        let newImages = [[],[],[],[],[]];
        additionalImages.forEach((col, index) => {
            newImages[index] = images[index].concat(additionalImages[index]);
        });
        _setImages(newImages);
    },[additionalImages]);

    useEffect(()=>{
        getContentSearch(null)
            .then(resp => {
                if (resp.status == 200) {
                    setImages(resp.data);
                }
            });
        getHomepageImages()
            .then(resp => {
                if (resp.status == 200) {
                    parseHomepageImages(resp.data);
                }
            })
    },[]);

    return(
        <div className={"homepage-div"}>
            <div className={"homepage-row-div"}>
                <div className={"homepage-img-div"} onClick={e => {openModal(0)}}>
                    {homepageImages[0] && (
                        <img src={"data:" + homepageImages[0].image_type + ";base64," + homepageImages[0].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(1)}}>
                    {homepageImages[1] && (
                        <img src={"data:" + homepageImages[1].image_type + ";base64," + homepageImages[1].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(2)}}>
                    {homepageImages[2] && (
                        <img src={"data:" + homepageImages[2].image_type + ";base64," + homepageImages[2].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(3)}}>
                    {homepageImages[3] && (
                        <img src={"data:" + homepageImages[3].image_type + ";base64," + homepageImages[3].img} />
                    )}
                </div>
                <div>
                    <button onClick={saveLayout} className={"btn btn-outline-light homepage-save-btn"}>Save</button>
                </div>
            </div>
            <div className={"homepage-row-div"}>
                <div className={"homepage-img-div"} onClick={e => {openModal(4)}}>
                    {homepageImages[4] && (
                        <img src={"data:" + homepageImages[4].image_type + ";base64," + homepageImages[4].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(5)}}>
                    {homepageImages[5] && (
                        <img src={"data:" + homepageImages[5].image_type + ";base64," + homepageImages[5].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(6)}}>
                    {homepageImages[6] && (
                        <img src={"data:" + homepageImages[6].image_type + ";base64," + homepageImages[6].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(7)}}>
                    {homepageImages[7] && (
                        <img src={"data:" + homepageImages[7].image_type + ";base64," + homepageImages[7].img} />
                    )}
                </div>
                <div>
                    <input className={"homepage-search-input"} placeholder={"what's the vibe?"}/>
                </div>
            </div>
            <div className={"homepage-row-div"}>
                <div className={"homepage-img-div"} onClick={e => {openModal(8)}}>
                    {homepageImages[8] && (
                        <img src={"data:" + homepageImages[8].image_type + ";base64," + homepageImages[8].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(9)}}>
                    {homepageImages[9] && (
                        <img src={"data:" + homepageImages[9].image_type + ";base64," + homepageImages[9].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(10)}}>
                    {homepageImages[10] && (
                        <img src={"data:" + homepageImages[10].image_type + ";base64," + homepageImages[10].img} />
                    )}
                </div>
                <div className={"homepage-img-div"} onClick={e => {openModal(11)}}>
                    {homepageImages[11] && (
                        <img src={"data:" + homepageImages[11].image_type + ";base64," + homepageImages[11].img} />
                    )}
                </div>
            </div>
            <Modal
                isOpen={browseImages}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"homepage-modal"}
            >
                {images && images.map((column) => (
                    <div className="image-column" id={"content-container-div"}>
                        {column.map((image) => (
                            <div className={"content-container"}>
                                <img className="content-image" alt="" src={"data:" + image.image_type + ";base64," + image.img}
                                onClick={e => {selectPhoto(image)}}/>
                            </div>
                        ))}
                    </div>
                ))}
            </Modal>
        </div>
    );
}

export default HomePageEditor
