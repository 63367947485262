import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {
    getAestheticPreviews,
    updateAesthetic,
    createAesthetic,
    deleteAestheticSubmissions, deleteAesthetic
} from "../services/AestheticService";
import pencil from "../icons/pencil.png";
import "../stylesheets/Aesthetics.css";
import heartImg from "../icons/DupeIcons-17-Heart.png";
import viewsImg from "../icons/DupeIcons-16-Views.png";
import Modal from "react-modal";


const Aesthetics  = () => {

    const [aesthetics, setAesthetics] = useState([]);
    const [editAesthetic, setEditAesthetic] = useState(false);
    const [deleteAestheticFlag, setDeleteAestheticFlag] = useState(false);
    const [selectedAesthetic, setSelectedAesthetic] = useState({});
    const [newAesthetic, setNewAesthetic] = useState("");

    function closeModals() {
        setEditAesthetic(false);
        setDeleteAestheticFlag(false);
        setSelectedAesthetic({});
        setNewAesthetic("");
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openEditAesthetic(aesthetic) {
        setSelectedAesthetic(aesthetic);
        setEditAesthetic(true);
    }

    function openNewAesthetic() {
        setSelectedAesthetic({"aesthetic" : "Create a New One"});
        setEditAesthetic(true);
    }

    function openDeleteAesthetic(aesthetic) {
        setSelectedAesthetic(aesthetic);
        setDeleteAestheticFlag(true);
    }

    function refreshAesthetics() {
        const token = localStorage.getItem("admin-token");
        getAestheticPreviews(token)
            .then((res) => {
                setAesthetics(res.data);
            })
            .catch(err => {

            })
    }

    const deleteAestheticFunc = (event) => {
        event.preventDefault();
        const token = localStorage.getItem("admin-token");
        deleteAesthetic(selectedAesthetic.id, token)
            .then((res) => {
                closeModals();
                refreshAesthetics();
            })
            .catch(err => {

            })
    }

    const updateAestheticEntry = (event) => {
        event.preventDefault();
        let newAestheticObj = {}
        if (selectedAesthetic.id) {
            newAestheticObj.id = selectedAesthetic.id
            newAestheticObj.aesthetic = newAesthetic
            const token = localStorage.getItem("admin-token");
            updateAesthetic(newAestheticObj, token)
                .then((res) => {
                    closeModals();
                    refreshAesthetics();
                })
                .catch(err => {

                })
        } else {
            newAestheticObj.aesthetic = newAesthetic
            const token = localStorage.getItem("admin-token");
            createAesthetic(newAestheticObj, token)
                .then((res) => {
                    closeModals();
                    refreshAesthetics();
                })
                .catch(err => {

                })
        }
    }

    useEffect(()=>{
        refreshAesthetics();
    },[]);

    return(
        <div>
            <button className={"btn btn-primary"} onClick={openNewAesthetic}>Create Aesthetic</button>
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Edit</th>
                    <th scope="col">Aesthetic</th>
                    <th scope="col">Post Count</th>
                    <th scope="col">Delete</th>
                </tr>
                </thead>
                <tbody>
            {aesthetics && aesthetics.map((aesthetic) => (
                <tr>
                    <th scope="row">
                        <img className={"edit-aesthetic-img"} src={pencil} onClick={e => {openEditAesthetic(aesthetic)}}/>
                    </th>
                    <td>{aesthetic.aesthetic}</td>
                    <td>{aesthetic.post_count}</td>
                    <td>
                        <button className={"btn btn-outline-danger"} onClick={e => {openDeleteAesthetic(aesthetic)}}>Delete</button>
                    </td>
                </tr>
                ))}
                </tbody>
            </table>
            <Modal
                isOpen={editAesthetic}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModals}
                className={"aesthetic-modal"}
            >
                <div className={"aesthetic-edit-container"}>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputEmail4">Current Name</label>
                                <input className="form-control" value={selectedAesthetic.aesthetic} disabled/>
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="inputPassword4">New Aesthetic</label>
                                <input className="form-control" value={newAesthetic} onChange={e => setNewAesthetic(e.target.value)}/>
                            </div>
                        </div>
                        <button className="btn btn-primary save-aesthetic-btn" onClick={updateAestheticEntry}>Save</button>
                    </form>
                </div>
            </Modal>
            <Modal
                isOpen={deleteAestheticFlag}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModals}
                className={"aesthetic-modal"}
            >
                <div className={"aesthetic-edit-container"}>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputEmail4">Current Name</label>
                                <input className="form-control" value={selectedAesthetic.aesthetic} disabled/>
                            </div>
                        </div>
                        <div className="form-row">
                            <h5 className={"h-warning"}>Are you sure you want to delete this aesthetic submission?</h5>
                        </div>
                        <button className="btn btn-outline-danger save-aesthetic-btn deny-aeth-sub-btn" onClick={deleteAestheticFunc}>Delete</button>
                        <button className="btn btn-outline-secondary save-aesthetic-btn" onClick={closeModals}>Cancel</button>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default Aesthetics
