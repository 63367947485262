import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {
    createNewTags, deleteLabel,
    getContentWithLabels
} from "../services/ContentService";
import "../stylesheets/TagEditor.css";
import plusIcon from "../icons/grey-plus-btn.png";
import Modal from "react-modal";
import {useNavigate, useSearchParams} from "react-router-dom";


const TagEditor  = () => {
    const [content, setContent] = useState([]);
    const [selectedPost, setSelectedPost] = useState([]);
    const [selectedTag, setSelectedTag] = useState("");
    const [newTags, setNewTags] = useState([]);
    const [addTagModalOpen, setAddTagModalOpen] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    function closeAddTagModal() {
        setAddTagModalOpen(false);
        setSelectedPost({});
        setNewTags([]);
    }

    function closeConfirmModal() {
        setConfirmDeleteModal(false);
    }

    function openAddModal(content) {
        setSelectedPost(content);
        setAddTagModalOpen(true);
    }

    function openDeleteModal(tag) {
        setSelectedTag(tag);
        setConfirmDeleteModal(true);
    }

    function addTag(event) {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                let newTag = {};
                newTag.label = event.target.value;
                newTag.score = 1.00;
                newTag.user_labeled = false;
                newTag.content_id = selectedPost.id
                setNewTags(newTags.concat(newTag));
                event.target.value = '';
        }
    }

    function removeTag(deleteTag) {
        const updatedTags = newTags.filter(tag => {
            if (deleteTag.label === tag.label) {
                return false;
            } else {
                return true;
            }
        })
        setNewTags(updatedTags);
    }

    function submitTags() {
        const token = localStorage.getItem("admin-token");
        createNewTags(token, newTags)
            .then(resp => {
                navigate(0);
            })
    }

    function deleteTag() {
        const token = localStorage.getItem("admin-token");
        deleteLabel(token, selectedTag.id)
            .then(resp => {
                navigate(0);
            })
    }

    function incrementPage() {
        console.log(page);
        //setPage(page+1);
        searchParams.set("page", page+1)
        setSearchParams(searchParams);
    }

    function decrementPage() {
        if (page > 1) {
            //setPage(page-1);
            searchParams.set("page", page-1)
            setSearchParams(searchParams);
        }
    }

    useEffect(()=>{
        const token = localStorage.getItem("admin-token");
        let pageParam = searchParams.get("page");
        if (pageParam == null) {
            pageParam = 1;
        }
        getContentWithLabels(token, pageParam)
            .then(resp => {
                if (resp.status == 200 && resp.data) {
                    setPage(parseInt(pageParam));
                    setContent(resp.data);
                }
            })
    },[searchParams]);

    return(
        <div>
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#" onClick={decrementPage}>Previous</a></li>
                    <li className="page-item"><a className="page-link" href="#">{page}</a></li>
                    <li class="page-item"><a class="page-link" href="#" onClick={incrementPage}>Next</a></li>
                </ul>
            </nav>
            {content && content.map((post) => (
                <div className={"tag-content-div"}>
                    {post.content_type !== "VIDEO" && (
                        <img className={"tag-preview-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + post.img_preview_id} />
                    )}
                    {post.content_type === "VIDEO" && (
                        <video className="content-video" controls loop autoPlay muted>
                            <source src={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + post.img_preview_id}
                                    type={"video/mp4"}/>
                        </video>
                    )}
                    <div className={"tags-container"}>
                        <div>
                            <h5 className={"blue-text"}>User Tags</h5>
                            {post.labels && post.labels.map((label) => (
                                <fragment>
                                    {label.user_labeled && (
                                        <p className={"label-p"}>{label.label}</p>
                                    )}
                                </fragment>
                            ))}
                        </div>
                        <div>
                            <h5 className={"blue-text"}>Dupe Tags</h5>
                            {post.labels && post.labels.map((label) => (
                                <fragment>
                                    {!label.user_labeled && (
                                        <p className={"label-p pointer"} onClick={event => openDeleteModal(label)}>X {label.label} - {label.score}</p>
                                    )}
                                </fragment>
                            ))}
                        </div>
                    </div>
                    <img className={"add-tag-img pointer"} src={plusIcon} onClick={event => openAddModal(post)} />
                </div>
                ))}
            <Modal
                isOpen={addTagModalOpen}
                onRequestClose={closeAddTagModal}
                className={"add-tag-modal"}
            >
                <div>
                    <img className={"new-tag-preview-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + selectedPost.img_preview_id} />
                    <div className={"new-tag-div"}>
                        <input className={"new-tag-input"} onKeyDown={addTag} placeholder={"Enter Tag Here"} />
                        <div>
                            {newTags && newTags.map((label) => (
                                <p className={"label-p new-p pointer"} onClick={event => removeTag(label)}>X {label.label}</p>
                            ))}
                        </div>
                    </div>
                    <button className={"blue-btn submit-tag-btn"} onClick={submitTags}>Submit</button>
                </div>
            </Modal>
            <Modal
                isOpen={confirmDeleteModal}
                onRequestClose={closeConfirmModal}
                className={"confirm-delete-modal"}
            >
                <div>
                    <h5>Are you sure you want to delete the tag: </h5>
                    <h5>{selectedTag.label}</h5>
                    <button className={"btn btn-secondary confirm-delete-tag-btn"} onClick={closeConfirmModal}>Cancel</button>
                    <button className={"btn btn-danger confirm-delete-tag-btn"} onClick={deleteTag}>Delete</button>
                </div>
            </Modal>
        </div>
    );
}

export default TagEditor
