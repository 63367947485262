import React, {useEffect, useState} from 'react'
import "../stylesheets/Content.css";
import Modal from "react-modal";
import {getContentSummary, likeContent, rateContent} from "../services/ContentService";
import heartImg from "../icons/DupeIcons-17-Heart.png";
import star from "../icons/star.png";
import viewsImg from "../icons/DupeIcons-16-Views.png";
import {useNavigate} from "react-router-dom";


const Content = ({content, profileFlag, editFunc}) => {

    const [images, _setImages] = useState(null);
    const [user, setUser] = useState(null);
    const [contentModal, setContentModal] = React.useState(false);
    const [contentImage, setContentImage] = React.useState("");
    const [contentSummary, setContentSummary] = React.useState("");
    const [style, setStyle] = React.useState({});
    const navigate = useNavigate();


    function closeContentModal() {
        setContentModal(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openContentModal(img) {
        setStyle({
            overlay: {
                backgroundColor: 'rgba(24, 47, 96, 0.80)',
                position: 'fixed',
                zIndex: 999
            }
        })
        setContentImage(img);
        getContentSummary(img.id)
            .then(response => {
                if (response.status === 200) {
                    setContentSummary(response.data);
                    setContentModal(true);
                }
            })
    }


    const setImages = (data) => {
        if (data) {
            const firstColumn = [];
            const secondColumn = [];
            const thirdColumn = [];
            const fourthColumn = [];
            const fifthColumn = [];
            data.forEach((image, index) => {
                if (index % 5 === 0) {
                    firstColumn.push(image)
                } else if (index % 5 === 1) {
                    secondColumn.push(image)
                } else if (index % 5 === 2) {
                    thirdColumn.push(image)
                } else if (index % 5 === 3) {
                    fourthColumn.push(image)
                } else if (index % 5 === 4) {
                    fifthColumn.push(image)
                }
            });
            const images = [firstColumn, secondColumn, thirdColumn, fourthColumn, fifthColumn];
            _setImages(images);
        }
    };

    function rankPhoto(event, rating, image) {
        event.stopPropagation();
        const token = localStorage.getItem("admin-token");
        let ratingRequest = {};
        ratingRequest.status = "APPROVED";
        ratingRequest.rating = rating;
        rateContent(ratingRequest, image.id, token)
            .then(response => {
                if (response.status == 200 && response.data.updated) {

                }
            });
        const childNodes = event.currentTarget.parentNode.childNodes;
        childNodes.forEach(function (child) {
            child.classList.remove('rated');
            if (child == event.currentTarget) {
                child.classList.add('rated');
            }
        })
        event.currentTarget.parentNode.nextSibling.classList.add("active");
    }

    function approvePhoto(event, image) {
        const token = localStorage.getItem("admin-token");
        let ratingRequest = {};
        ratingRequest.status = "APPROVED";
        rateContent(ratingRequest, image.id, token)
            .then(response => {
                if (response.status == 200 && response.data.updated) {

                }
            });
        event.stopPropagation();
        event.currentTarget.classList.add("active");
        event.currentTarget.nextSibling.classList.remove("active");
    }

    function denyPhoto(event, image) {
        const token = localStorage.getItem("admin-token");
        let ratingRequest = {};
        ratingRequest.status = "DENIED";
        rateContent(ratingRequest, image.id, token)
            .then(response => {
                if (response.status == 200 && response.data.updated) {

                }
            });
        event.stopPropagation();
        event.currentTarget.classList.add("active");
        event.currentTarget.previousSibling.classList.remove("active");
    }

    useEffect(()=>{
        setImages(content);
    },[content]);

    return(
        <div>
            <div>
                {images && images.map((column) => (
                    <div className="image-column">
                        {column.map((image) => (
                            <div className={"content-container"}>
                                {image.content_type === "VIDEO" && (
                                    <video className="content-image" controls loop autoPlay muted>
                                        <source src={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + image.img_preview_id}
                                                type={"video/mp4"}/>
                                    </video>
                                )}
                                {image.content_type !== "VIDEO" && (
                                    <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} onClick={() => openContentModal(image)} />
                                )}
                                <div className={"content-overlay-div"} onClick={() => openContentModal(image)}>
                                    <div className={"star-bar"}>
                                        <img className={"star-icon"} onClick={(e) => rankPhoto(e,1, image)} src={star}/>
                                        <img className={"star-icon"} onClick={(e) => rankPhoto(e,2, image)} src={star}/>
                                        <img className={"star-icon"} onClick={(e) => rankPhoto(e,3, image)} src={star}/>
                                        <img className={"star-icon"} onClick={(e) => rankPhoto(e,4, image)} src={star}/>
                                        <img className={"star-icon"} onClick={(e) => rankPhoto(e,5, image)} src={star}/>
                                    </div>
                                    <button className={"btn btn-outline-light approve-btn"} onClick={(e) => approvePhoto(e, image)} >Approve</button>
                                    <button className={"btn btn-outline-danger deny-btn"} onClick={(e) => denyPhoto(e, image)} >Deny</button>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <Modal
                isOpen={contentModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeContentModal}
                className={"content-modal"}
                style={style}
            >
                <div className={"content-summary"}>
                    <div className={"selected-content"}>
                        <img alt="not fount" src={"data:" + contentImage.image_type + ";base64," + contentImage.img} />
                    </div>
                    <div className={"content-info"}>
                        <div className={"user-div"} onClick={() => navigate('/profile/' + contentSummary.username)}>
                            <img src={"data:" + contentSummary.user_image_type + ";base64," + contentSummary.user_image}/>
                            <div className={"d-inline"}>
                                <h5>{contentSummary.user}</h5>
                            </div>
                            <div className={"d-inline"}>
                                <div className={"stats-div"}>
                                    <img className={"likes-img"} src={heartImg}/>
                                    <p className={"d-inline"}>{contentSummary.likes} &nbsp;Likes</p>
                                </div>
                                <div className={"stats-div"}>
                                    <img className={"likes-img"} src={viewsImg}/>
                                    <p className={"d-inline"}>{contentSummary.views} &nbsp;Views</p>
                                </div>
                            </div>
                        </div>
                        <div className={"aesthetics"}>
                            <h5>Aesthetics</h5>
                            <div>
                                {contentSummary.aesthetics && contentSummary.aesthetics.map((aesthetic) => (
                                    <fragment>
                                        <a className={"aesthetic-button"} href = {'/?aesthetic=' + aesthetic.id}>{aesthetic.aesthetic}</a>
                                    </fragment>
                                ))}
                            </div>
                        </div>
                        <div className={"labels"}>
                            {contentSummary.labels && contentSummary.labels.map((label) => (
                                <fragment>
                                    <a className={"btn"} href = {'/?search=' + label.label}>{label.label}</a>
                                </fragment>
                            ))}
                        </div>
                        <button className={"download-btn"}>Download</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Content
