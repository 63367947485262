import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import axios from "axios";
import {getAestheticContent, getContentSearch, getUnratedContent} from "../services/ContentService";
import Content from "./Content";
import {useSearchParams} from "react-router-dom";
import NavBar from "./NavBar";


const Discovery  = () => {

    const [images, setImages] = useState(null);
    const [selectedAestheticId, setSelectedAestheticId] = useState(null);
    const [aesthetics, setAesthetics] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const searchAesthetic = (aestheticId) => {
        getAestheticContent(aestheticId)
            .then((res) => {
                if (res.status == 200) {
                    setImages(res.data);
                }
            })
    }

    useEffect(()=>{
        if (!searchParams.get("search") && !searchParams.get("aesthetic")) {
            const token = localStorage.getItem("admin-token");
            getUnratedContent(token)
                .then((res) => {
                    setImages(res.data);
                })
                .catch(err => {

                })
        } else if (!searchParams.get("search") && searchParams.get("aesthetic")) {
            searchAesthetic(searchParams.get("aesthetic"));
            setSelectedAestheticId(searchParams.get("aesthetic"));
        }
    },[]);

    return(
        <div>
            <Content content={images} addCollectionEnabled={true}/>
        </div>
    );
}

export default Discovery
